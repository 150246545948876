import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import ReservationsTable from "./ReservationsTable";
import {
    getAirlines,
    getAllReservations,
    getAllReservationsByDateRange,
    updateReservation,
} from "../../controllers/reservations_controller";
import { sendMail, parserEmailData } from "../../controllers/email_controller";
import DetailsModal from "./DetailsModal";
import EditModal from "./EditModal";
import { getAllTransportUnits } from "../../controllers/transport_controller";
import ReservationsExcelExport from "./ReservationsExcelExport";
import Swal from "sweetalert2";

const getInitialQueryDates = () => {
    let queryInitDate = new Date();
    queryInitDate.setHours(0, 0, 0);
    let queryEndDate = new Date();
    queryEndDate.setHours(23, 59, 59);
    return {
        init_date: queryInitDate,
        end_date: queryEndDate,
    };
};

const Reservations = () => {
    const [reservationData, setReservationData] = useState([]);
    const [transportUnitsData, setTransportUnitsData] = useState([]);
    const [airlinesData, setAirlinesData] = useState([]);
    const [airlinesOptionsData, setAirlinesOptionsData] = useState([]);
    const [queryDates, setQueryDates] = useState(getInitialQueryDates);
    const [detailsModalController, setDetailsModalController] = useState(false);
    const [editModalController, setEditModalController] = useState(false);
    const [detailsModalData, setDetailsModalData] = useState({});
    const [editModalData, setEditModalData] = useState({});
    const [excelDownloadController, setExcelDownloadController] = useState(false);
    const [modalLoaderController, setModalLoaderController] = useState(false);
    const [refreshController, setRefreshController] = useState(false);

    useEffect(() => {
        getAllTransportUnits()
            .then((response) => {
                setTransportUnitsData(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
        getAirlines().then((response) => {
            if (response.data.length > 0) {
                setAirlinesData(response.data);
                let auxAirlinesOptions = [];
                response.data.forEach((item) => {
                    auxAirlinesOptions.push({
                        label: item.label,
                        value: item.name,
                    });
                });
                setAirlinesOptionsData(auxAirlinesOptions);
            }
        });
        getAllReservationsByDateRange(queryDates)
            .then((res) => {
                let responseDataFormated = [];
                res.data.results.forEach((item) => {
                    item.id = item.id_reservation;
                    item.createdAt = moment(item.createdAt).format("LLL");
                    item.arrival_date = moment(item.arrival_datetime).format("LLL");
                    item.arrival_datetime = moment(item.arrival_datetime).toDate();

                    if (item.trip_type !== "One Way") {
                        item.type = "Round Trip";
                        item.departure_date = moment(item.departure_datetime).format("LLL");
                        item.departure_datetime = moment(item.departure_datetime).toDate();
                    } else {
                        item.departure_date = "";
                        item.departure_datetime = "";
                        item.type = "One Way";
                    }
                    responseDataFormated.push(item);
                });
                setReservationData(responseDataFormated);
            })
            .catch((e) => {
                console.log(e);
            });
        setRefreshController(false);
    }, [queryDates, refreshController]);

    const handleDateRangeChange = (event, picker) => {
        setQueryDates({
            init_date: picker.startDate.toDate(),
            end_date: picker.endDate.toDate(),
        });
    };

    const handleSeeDetails = (data) => {
        setDetailsModalController(true);
        setDetailsModalData(data);
        console.log(data);
    };

    const handleEdit = (data) => {
        setEditModalController(true);
        setEditModalData(data);
    };

    const toggleDetailsModal = () => {
        setDetailsModalController(!detailsModalController);
        setDetailsModalData({});
    };

    const toggleEditModal = () => {
        setEditModalController(!editModalController);
        setEditModalData({});
    };

    const handleEditFormSubmit = (e) => {
        e.preventDefault();
        setModalLoaderController(true);
        let dataToSend = {
            folio: editModalData.folio,
            trip_type: editModalData.trip_type,
            unit: editModalData.unit,
            pickup_location: editModalData.pickup_location,
            destination_location: editModalData.destination_location,
            total_passengers: parseInt(editModalData.total_passengers),
            fullname: editModalData.fullname,
            email: editModalData.email,
            cellphone: editModalData.cellphone,
            arrival_datetime: moment(editModalData.arrival_datetime).format("YYYY-MM-DD hh:mm:ss a"),
            arrival_airline: editModalData.arrival_airline,
            arrival_flight_number: editModalData.arrival_flight_number,
            departure_datetime: moment(editModalData.departure_datetime).format("YYYY-MM-DD hh:mm:ss a"),
            departure_airline:
                editModalData.trip_type === "Round Trip" ? editModalData.departure_airline : "",
            departure_flight_number:
                editModalData.trip_type === "Round Trip" ? editModalData.departure_flight_number : "",
            observations: editModalData.observations,
            payment_method: editModalData.payment_method,
            discount_code: editModalData.discount_code,
            discount_percent: editModalData.discount_percent,
            total_payment: editModalData.total_payment,
        };
        updateReservation(editModalData.id_reservation, dataToSend)
            .then((res) => {
                console.log(res);
                setModalLoaderController(false);
                Swal.fire("Success!", "The reservation has been updated.", "success").then(() => {
                    toggleEditModal();
                    setRefreshController(true);
                });
            })
            .catch((e) => {
                console.log(e);
                setModalLoaderController(false);
                Swal.fire("Oops!", "Something went wrong when updating the reservation...", "error");
            });
    };

    const resendEmail = (e) => {
        Swal.fire({
            title: "Are you sure you want to resent confirmation email?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                let arrival_date_time = moment(e.arrival_datetime).format("YYYY-MM-DD hh:mm:ss a");
                e["arrival_datetime"] = arrival_date_time;

                if (e.departure_datetime !== "") {
                    let departure_datetime = moment(e.departure_datetime).format("YYYY-MM-DD hh:mm:ss a");
                    e["departure_datetime"] = departure_datetime;
                }

                //console.log('elegido', e)
                let email_data = parserEmailData(e);
                //console.log('parseado', email_data)
                sendMail(email_data).then((_response) => {
                    Swal.fire("", "The email was resend", "success");
                });
            }
        });
    };
    console.log(reservationData);
    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} md={6} className="d-flex align-items-end">
                        <h1>REGISTERED RESERVATIONS</h1>
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-between justify-content-md-end align-items-end">
                        <div className="datepicker-range">
                            <small>Datepicker Reservations:</small>
                            <DateRangePicker onApply={handleDateRangeChange}>
                                <input type="text" className="form-control input-range" />
                            </DateRangePicker>
                        </div>
                        <div>
                            {excelDownloadController ? (
                                <ReservationsExcelExport
                                    records={reservationData}
                                    controller={setExcelDownloadController}
                                />
                            ) : (
                                <Button
                                    className="button-excell"
                                    onClick={() => setExcelDownloadController(true)}>
                                    Prepare Excel
                                </Button>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} className="my-4">
                        <ReservationsTable
                            records={reservationData}
                            handleSeeDetails={handleSeeDetails}
                            handleEdit={handleEdit}
                            resendEmail={resendEmail}
                        />
                    </Col>
                </Row>
            </Container>
            <DetailsModal
                modalController={detailsModalController}
                toggleModal={toggleDetailsModal}
                modalData={detailsModalData}
                units={transportUnitsData}
            />
            <EditModal
                modalController={editModalController}
                toggleModal={toggleEditModal}
                modalData={editModalData}
                airlinesOptions={airlinesOptionsData}
                setData={setEditModalData}
                unitsOptions={transportUnitsData}
                handleFormSubmit={handleEditFormSubmit}
                loader={modalLoaderController}
            />
        </Fragment>
    );
};

export default Reservations;
