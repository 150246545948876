import React, { Component } from "react";
import { Button } from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

export default class PlacesTable extends Component {
    /*{
        key: "zone_number",
        text: "Zone number",
        align: "center",
        sortable: true,
    },*/
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "name",
                text: "Name",
                align: "left",
                sortable: true,
            },
            {
                key: "label",
                text: "Details",
                align: "left",
                sortable: true,
            },
            {
                key: "_actions",
                text: "Actions",
                alignItems: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <Button color="primary" onClick={() => this.props.editHandler(record)}>
                                Edit
                            </Button>
                            <Button
                                className="ml-2"
                                color="danger"
                                onClick={() => this.props.deleteHandler(record)}>
                                Delete
                            </Button>
                        </div>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
            },
        };
        this.state = {
            records: this.props.records,
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        };
    };

    render() {
        return (
            <div className="table-responsive">
                <ReactDatatable config={this.config} records={this.state.records} columns={this.columns} />
            </div>
        );
    }
}
