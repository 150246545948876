import React, { Component } from "react";
import { Button } from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

export default class RatesTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "origin_name",
                text: "Origin Zone Name",
                align: "center",
                sortable: true,
            },
            {
                key: "destination_name",
                text: "Destination Zone Name",
                align: "center",
                sortable: true,
            },
            {
                key: "unit",
                text: "Transport",
                align: "left",
                sortable: true,
            },
            {
                key: "oneWay",
                text: "One Way Rate",
                align: "left",
                sortable: true,
                cell: (record) => <div>{`$${record.oneWay} USD`}</div>,
            },
            {
                key: "roundTrip",
                text: "Round Trip Rate",
                align: "left",
                sortable: true,
                cell: (record) => <div>{`$${record.roundTrip} USD`}</div>,
            },
            {
                key: "_actions",
                text: "Actions",
                alignItems: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <div className="d-flex justify-content-end" key={record.id_rate}>
                            <Button color="primary" onClick={() => this.props.editHandler(record)}>
                                Edit
                            </Button>
                            <Button
                                className="ml-2"
                                color="danger"
                                onClick={() => this.props.deleteHandler(record)}>
                                Delete
                            </Button>
                        </div>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
            },
        };
        this.state = {
            records: this.props.records,
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        };
    };

    render() {
        return (
            <div className="table-responsive">
                <ReactDatatable config={this.config} records={this.state.records} columns={this.columns} />
            </div>
        );
    }
}
