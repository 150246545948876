import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RatesTable from "./RatesTable";
import { getAllTransportUnits } from "../../controllers/transport_controller";
import { getAllZones } from "../../controllers/zones_controller";
import { getAllRates, createNewRate, updateRate, deleteRate } from "../../controllers/rates_controller";
import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";

const Rates = () => {
    const [modalController, setModalController] = useState(false);
    const [transportUnitsData, setTransportUnitsData] = useState([]);
    const [transportUnitsOptionsData, setTransportUnitsOptionsData] = useState([]);
    const [zonesData, setZonesData] = useState([]);
    const [zonesOptionsData, setZonesOptionsData] = useState([]);
    const [ratesData, setRatesData] = useState([]);
    const [formData, setFormData] = useState({
        unit: "",
        origin: "",
        destination: "",
        oneWay: "",
        roundTrip: "",
        id_rate: "",
    });
    const [editController, setEditController] = useState(false);
    const [loaderController, setLoaderController] = useState(false);
    const [refreshController, setRefreshController] = useState(false);

    useEffect(() => {
        getAllTransportUnits()
            .then((response) => {
                let responseOptionsFormated = [];
                response.data.forEach((item) => {
                    responseOptionsFormated.push({
                        label: item.name,
                        value: item.id_unit,
                    });
                });
                setTransportUnitsOptionsData(responseOptionsFormated);
                setTransportUnitsData(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        getAllZones()
            .then((response) => {
                let responseDataFormated = [];
                let responseOptionsFormated = [];
                response.data.forEach((item) => {
                    responseOptionsFormated.push({
                        label: item.label,
                        value: item.id_zone,
                    });
                });
                response.data.forEach((item) => {
                    item.id = item.id_zone;
                    item.zone_number = "" + item.zone_number;
                    responseDataFormated.push(item);
                });
                setZonesOptionsData(responseOptionsFormated);
                setZonesData(responseDataFormated);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        if (transportUnitsData.length > 0 && zonesData.length > 0) {
            getAllRates()
                .then((response) => {
                    let responseDataFormated = [];
                    response.data.forEach((item, index) => {
                        item.id = index;
                        item.origin_name = zonesData.filter((zone) => zone.id_zone === item.origin)[0].label;
                        item.destination_name = zonesData.filter(
                            (zone) => zone.id_zone === item.destination
                        )[0].label;
                        item.unit = transportUnitsData.filter(
                            (unit) => unit.id_unit === item.id_unit
                        )[0].name;
                        responseDataFormated.push(item);
                    });
                    responseDataFormated.sort((a, b) => {
                        if (a.origin > b.origin) {
                            return 1;
                        }
                        if (b.origin > a.origin) {
                            return -1;
                        }
                        return 0;
                    });
                    setRatesData(responseDataFormated);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        setRefreshController(false);
    }, [transportUnitsData, zonesData, refreshController]);

    const openModal = () => {
        setModalController(true);
        setEditController(false);
        setFormData({ unit: "", origin: "", destination: "", oneWay: "", roundTrip: "", id_rate: "" });
    };

    const toggleModal = () => {
        setFormData({ unit: "", origin: "", destination: "", oneWay: "", roundTrip: "", id_rate: "" });
        setModalController(!modalController);
    };

    const handleFormSelectUnitChange = (selected) => {
        setFormData({
            ...formData,
            unit: selected,
        });
    };

    const handleFormSelectOriginZoneChange = (selected) => {
        setFormData({
            ...formData,
            origin: selected,
        });
    };

    const handleFormSelectDestinationZoneChange = (selected) => {
        setFormData({
            ...formData,
            destination: selected,
        });
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmitCreate = (e) => {
        e.preventDefault();
        if (
            ratesData.filter(
                (rate) =>
                    rate.id_unit === formData.unit.value &&
                    rate.origin === formData.origin.value &&
                    rate.destination === formData.destination.value
            ).length > 0
        ) {
            Swal.fire(
                "Error",
                "The current rate has already been stated, please either edit or delete it.",
                "error"
            );
        } else {
            setLoaderController(true);
            let dataToSend = {
                origin: formData.origin.value,
                destination: formData.destination.value,
                id_unit: formData.unit.value,
                oneWay: "" + formData.oneWay,
                roundTrip: "" + formData.roundTrip,
            };
            createNewRate(dataToSend)
                .then((res) => {
                    setLoaderController(false);
                    Swal.fire("Success!", "The rate has been created successfully.", "success").then(() => {
                        toggleModal();
                        setRefreshController(true);
                    });
                })
                .catch((e) => {
                    console.log(e);
                    Swal.fire("Oops!", "Something went wrong when creating new rate", "error");
                    setLoaderController(false);
                });
        }
    };

    const handleDeleteRate = (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "The rate information will be deleted!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRate(data.id_rate)
                    .then(() => {
                        Swal.fire("Success!", "The rate has been deleted successfully.", "success").then(
                            () => {
                                setRefreshController(true);
                            }
                        );
                    })
                    .catch((e) => {
                        Swal.fire("Oops!", "Something went wrong when deleting the selected rate", "error");
                    });
            }
        });
    };

    const handleEditRate = (data) => {
        setEditController(true);
        setFormData({
            unit: {
                value: data.id_unit,
                label: transportUnitsData.filter((unit) => unit.id_unit === data.id_unit)[0].name,
            },
            origin: {
                value: data.origin,
                label: zonesOptionsData.filter((zone) => zone.value === data.origin)[0].label,
            },
            destination: {
                value: data.destination,
                label: zonesOptionsData.filter((zone) => zone.value === data.destination)[0].label,
            },
            oneWay: data.oneWay,
            roundTrip: data.roundTrip,
            id_rate: data.id_rate,
        });
        setModalController(true);
    };

    const handleFormSubmitEdit = (e) => {
        e.preventDefault();

        setLoaderController(true);
        let dataToSend = {
            origin: formData.origin.value,
            destination: formData.destination.value,
            id_unit: formData.unit.value,
            oneWay: "" + formData.oneWay,
            roundTrip: "" + formData.roundTrip,
        };
        updateRate(formData.id_rate, dataToSend)
            .then((res) => {
                setLoaderController(false);
                Swal.fire("Success!", "The rate has been updated successfully.", "success").then(() => {
                    toggleModal();
                    setRefreshController(true);
                });
            })
            .catch((e) => {
                console.log(e);
                Swal.fire("Oops!", "Something went wrong when updating new rate", "error");
                setLoaderController(false);
            });
    };

    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                        <h1>RATES</h1>
                        <Button color="primary" onClick={openModal}>
                            <FontAwesomeIcon className="mr-2" icon="plus-circle" />
                            Add Rate
                        </Button>
                    </Col>
                    <Col xs={12} className="my-4">
                        <RatesTable
                            records={ratesData}
                            deleteHandler={handleDeleteRate}
                            editHandler={handleEditRate}
                        />
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modalController} size="lg">
                <LoadingOverlay active={loaderController} spinner text="Processing data...">
                    <ModalHeader toggle={toggleModal}>
                        {editController ? "Edit Rate" : "Add New Rate"}
                    </ModalHeader>
                    <form onSubmit={editController ? handleFormSubmitEdit : handleFormSubmitCreate}>
                        <ModalBody>
                            <Row>
                                <Col xs={6} className="my-3">
                                    <Label>Select Origin Zone</Label>
                                    <Select
                                        options={zonesOptionsData}
                                        value={formData.origin}
                                        onChange={handleFormSelectOriginZoneChange}
                                        isSearchable
                                        isClearable={false}
                                        isDisabled={editController}
                                    />
                                </Col>
                                <Col xs={6} className="my-3">
                                    <Label>Select Destination Zone</Label>
                                    <Select
                                        options={zonesOptionsData}
                                        value={formData.destination}
                                        onChange={handleFormSelectDestinationZoneChange}
                                        isSearchable
                                        isClearable={false}
                                        isDisabled={editController}
                                    />
                                </Col>
                                <Col xs={6} className="my-3">
                                    <Label>Select Transport:</Label>
                                    <Select
                                        options={transportUnitsOptionsData}
                                        value={formData.unit}
                                        onChange={handleFormSelectUnitChange}
                                        isSearchable
                                        isClearable={false}
                                        isDisabled={editController}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} className="my-3">
                                    <Label>OneWay Rate:</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="oneWay"
                                        onChange={handleInputChange}
                                        value={formData.oneWay}
                                        required
                                    />
                                </Col>
                                <Col xs={6} className="my-3">
                                    <Label>Round Trip Rate:</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="roundTrip"
                                        onChange={handleInputChange}
                                        value={formData.roundTrip}
                                        required
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" type="button" onClick={toggleModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit">
                                {editController ? "Edit" : "Add"}
                            </Button>
                        </ModalFooter>
                    </form>
                </LoadingOverlay>
            </Modal>
        </Fragment>
    );
};

export default Rates;
