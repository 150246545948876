import axios from "axios";
import { configs } from "../configs/configs";

export const getAllRates = () => {
    return axios.get(`${configs.URL_API_BASE}/rates`);
};
export const createNewRate = (data) => {
    return axios.post(`${configs.URL_API_BASE}/rates`, data);
};
export const updateRate = (id, data) => {
    return axios.put(`${configs.URL_API_BASE}/rates/${id}`, data);
};
export const deleteRate = (id) => {
    return axios.delete(`${configs.URL_API_BASE}/rates/${id}`);
};
