import React, { useState } from "react";
import { handlelogout } from "../../controllers/auth_controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";

const Header = () => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const logOut = () => {
        handlelogout();
    };

    return (
        <header className="container-fluid topbar">
            <Navbar color="white" light expand="md">
                <NavbarBrand href="/reservations">
                    <img
                        src={process.env.PUBLIC_URL + "/logo.png"}
                        width="100"
                        className="d-inline-block align-top"
                        alt="Transroute"
                        loading="lazy"
                    />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={!isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink href="/reservations">Reservations</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/transports">Transports</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/rates">Rates</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/zones">Zones</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/places">Places</NavLink>
                        </NavItem>
                        {parseInt(localStorage.getItem("tr_rol")) === 1 && (
                            <NavItem>
                                <NavLink href="/users">Users</NavLink>
                            </NavItem>
                        )}
                        <NavItem>
                            <NavLink className="logout_link" href={null} onClick={logOut}>
                                <FontAwesomeIcon className="mr-2" icon="sign-out-alt" />
                                Logout
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
};
export default Header;
