import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Header from "../components/utils/Header";
import Places from "../components/places/Places";
import Rates from "../components/rates/Rates";
import Reservations from "../components/reservations/Reservations";
import Transports from "../components/transport/Transports";
import Zones from "../components/zones/Zones";
import Footer from "../components/utils/Footer";
export const DashboardRoutes = () => {
    return (
        <>
            <Header />
            <div>
                <Switch>
                    <Route exact path="/reservations" component={Reservations} />
                    <Route exact path="/transports" component={Transports} />
                    <Route exact path="/rates" component={Rates} />
                    <Route exact path="/zones" component={Zones} />
                    <Route exact path="/places" component={Places} />
                    <Route exact path="/users" component={Reservations} />
                    <Redirect to="/reservations" />
                </Switch>
            </div>
            <Footer />
        </>
    );
};
