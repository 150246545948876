import axios from "axios";
import { configs } from "../configs/configs";

export const getAllReservations = () => {
    return axios.get(`${configs.URL_API_BASE}/reservations`);
};
export const getAllReservationsByDateRange = (dateRange) => {
    return axios.get(`${configs.URL_API_BASE}/reservations`, { params: dateRange });
};
export const updateReservation = (id, dataToUpdate) => {
    return axios.put(`${configs.URL_API_BASE}/reservations/${id}`, dataToUpdate);
};

export const getAirlines = () => {
    return axios.get(`${configs.URL_API_BASE}/airlines`);
};
