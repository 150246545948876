import React, { Fragment, useState } from 'react'
import { Container, Row, Col, Alert, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { doLogin } from './../../controllers/auth_controller';
const LoginScreen = () => {
   
    return (
        <Fragment>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xs={12} md={7} lg={5}>
                        <div className="pt-5 pb-1 mt-auto d-flex justify-content-center">
                            <img width="300" src={process.env.PUBLIC_URL + '/logo192.png'} className="logo-login" alt="Transroute" />
                        </div>
                        { 
                            //error && <Alert className="alert alert-danger" role="alert">{''}</Alert>
                        }
                        <div className="login-content p-4 bg-grey">
                            <h3 className="text-dark font-size-30 mb-2 text-center">Login</h3>
                            <Form>
                                <FormGroup>
                                    <Label 
                                        htmlFor="username">Email</Label>
                                    <Input 
                                        type="email"  
                                        name="email" 
                                        id="email" 
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label
                                        htmlFor="password">Password</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        required
                                    />
                                </FormGroup>
                                <Button
                                    color="primary"
                                    block
                                >
                                    Log in
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    )
}

export default LoginScreen;