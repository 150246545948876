import React from "react";
import { CardImg, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function DetailsModal({ modalController, toggleModal, modalData, units }) {
    return (
        <Modal isOpen={modalController} size="lg" scrollable={false}>
            <ModalHeader toggle={toggleModal}>RESERVATION ID: {modalData.folio}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={6} className="my-3">
                        <h3>{`TOTAL: ${modalData.total_payment}`.toUpperCase()}</h3>
                        <hr />
                        <p>
                            <b>Trip type:</b> {modalData.trip_type}
                        </p>
                        <p>
                            <b>Passengers:</b> {modalData.total_passengers}
                        </p>
                        <p>
                            <b>Transport selected:</b> {modalData.unit}
                        </p>
                        <p>
                            <b>Pick-up location:</b> {modalData.pickup_location}
                        </p>
                        <p>
                            <b>Destination:</b> {modalData.destination_location}
                        </p>
                    </Col>
                    <Col xs={6} className="my-3">
                        {units.length > 0 && modalData.unit !== undefined && (
                            <CardImg
                                top
                                width="100%"
                                src={units.filter((unit) => unit.name === modalData.unit)[0].image}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="my-3">
                        <hr />
                        <p>Contact/Traveler information: </p>
                        <p>
                            <b>Full name:</b> {modalData.fullname}
                        </p>
                        <p>
                            <b>Phone:</b> {modalData.cellphone}
                        </p>
                        <p>
                            <b>E-mail:</b> {modalData.email}
                        </p>
                    </Col>
                    <Col xs={6} className="my-3">
                        <hr />
                        <p>Comments:</p>
                        <p>{modalData.observations}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="my-3">
                        <hr />
                        <p>Pickup information: </p>
                        <p>
                            <b>Date/Time:</b> {modalData.arrival_date}
                        </p>
                        <p>
                            <b>Flight number</b> {modalData.arrival_flight_number}
                        </p>
                        <p>
                            <b>Airline Name</b> {modalData.arrival_airline}
                        </p>
                    </Col>
                    {modalData.trip_type !== "One Way" && (
                        <Col xs={6} className="my-3">
                            <hr />
                            <p>Departure information: </p>
                            <p>
                                <b>Date/Time:</b> {modalData.departure_date}
                            </p>
                            <p>
                                <b>Flight number</b> {modalData.departure_flight_number}
                            </p>
                            <p>
                                <b>Airline Name</b> {modalData.departure_airline}
                            </p>
                        </Col>
                    )}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleModal}>
                    Close
                </Button>
                <Button color="primary" onClick={toggleModal}>
                    Accept
                </Button>
            </ModalFooter>
        </Modal>
    );
}
