import axios from "axios";
import { configs } from "../configs/configs";

export const sendMail = (data) => {
    return axios.post(`${configs.URL_API_BASE}/send-email`, data);
};

export const parserEmailData = (data) => {
    let obj = {
        email: data.email,
        reservation: {
            folio: data.folio,
            payment_id: data.payment_id,
            search_params: {
                pickup_location: data.pickup_location,
                destination: data.destination_location,
                n_passengers: data.total_passengers,
                trip_type: data.trip_type,
                price: data.total_payment,
                transportation: data.unit,
                comments: data.observations,
            },
            arrival: {
                date_time: data.arrival_datetime,
                airline: data.arrival_airline,
                fligth_number: data.arrival_flight_number,
            },
            customer: {
                fullname: data.fullname,
                email: data.email,
                phone: data.cellphone,
            },
        },
    };

    if (data.trip_type === "Round Trip") {
        obj["reservation"]["departure"] = {
            date_time: data.departure_datetime,
            airline: data.departure_airline,
            fligth_number: data.departure_flight_number,
        };
    } else {
        obj["reservation"]["departure"] = null;
    }

    return obj;
};
