import React from 'react';
// import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { AppRouter } from './routes/AppRouter';
import {
  faPlaneArrival,
  faPlaneDeparture,
  faCalendarAlt,
  faUsers,
  faUser,
  faSuitcase,
  faRoute,
  faExclamationTriangle,
  faEdit,
  faShuttleVan,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faPenAlt,
  faLock,
  faUserFriends,
  faFileInvoiceDollar,
  faSignOutAlt,
  faDownload,
  faPlusCircle,
  faPaperPlane,
  faInfoCircle

} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faSignOutAlt, faInfoCircle, faEdit, faPaperPlane, fab, faSignOutAlt, faDownload, faFileInvoiceDollar, faPlaneArrival, faPlaneDeparture, faCalendarAlt, faUsers, faSuitcase, faRoute, faExclamationTriangle, faShuttleVan, faMapMarkedAlt, faMapMarkerAlt, faUser, faPhone, faEnvelope, faPenAlt, faLock, faUserFriends,faPlusCircle);

export const App = () => <AppRouter />