import React, { Component } from "react";
import { Button } from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ReservationsTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "folio",
                text: "ID Reservation",
                align: "left",
                sortable: true,
            },
            {
                key: "createdAt",
                text: "Register Date",
                align: "left",
                sortable: true,
            },
            {
                key: "type",
                text: "Type",
                align: "left",
                sortable: true,
            },
            {
                key: "fullname",
                text: "Client",
                align: "left",
                sortable: true,
            },
            {
                key: "arrival_date",
                text: "Arrival",
                align: "left",
                sortable: true,
            },
            {
                key: "arrival_flight_number",
                text: "Flight number",
                align: "left",
                sortable: true,
            },
            {
                key: "departure_date",
                text: "Departure",
                align: "left",
                sortable: true,
            },
            {
                key: "unit",
                text: "Transport",
                align: "left",
                sortable: true,
            },
            {
                key: "_actions",
                text: "Actions",
                cell: (record) => {
                    return (
                        <div className="d-flex">
                            <div className="mx-1 button-actions">
                                <Button color="primary" onClick={() => this.props.handleSeeDetails(record)}>
                                    <FontAwesomeIcon icon="info-circle" />
                                </Button>
                            </div>
                            <div className="mx-1 button-actions">
                                <Button color="secondary" onClick={() => this.props.handleEdit(record)}>
                                    <FontAwesomeIcon icon="edit" />
                                </Button>
                            </div>
                            <div className="mx-1 button-actions">
                                <Button color="warning">
                                    <FontAwesomeIcon
                                        icon="paper-plane"
                                        onClick={() => this.props.resendEmail(record)}
                                    />
                                </Button>
                            </div>
                        </div>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
            },
        };
        this.state = {
            records: this.props.records,
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        };
    };

    render() {
        return (
            <div className="table-responsive">
                <ReactDatatable config={this.config} records={this.state.records} columns={this.columns} />
            </div>
        );
    }
}
