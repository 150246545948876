import axios from "axios";
import { configs } from "../configs/configs";
/**
 * props: email, password
 */
export const doLogin = (props) => {
    return axios.post(`${configs.URL_API_BASE}/login`, props);
};

export const validateTokenFromAPI = () => {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("tr_token");
    return axios.get(`${configs.URL_API_BASE}/verifytoken`, {});
};

export const handlelogout = () => {
    localStorage.removeItem("tr_token");
    localStorage.removeItem("tr_email");
    localStorage.removeItem("tr_rol");
    localStorage.removeItem("tr_id");
    window.location.href = "/";
};
