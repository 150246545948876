import axios from "axios";
import { configs } from "../configs/configs";

export const getAllPlaces = () => {
    return axios.get(`${configs.URL_API_BASE}/places`);
};
export const createNewPlace = (data) => {
    return axios.post(`${configs.URL_API_BASE}/places`, data);
};
export const updatePlace = (id, data) => {
    return axios.put(`${configs.URL_API_BASE}/places/${id}`, data);
};
export const deletePlace = (id) => {
    return axios.delete(`${configs.URL_API_BASE}/places/${id}`);
};
